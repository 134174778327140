import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.svg';

export default function Header({ title, logoUrl, showHome }) {
  const navigate = useNavigate();

  return(
    <header>
      <div className='header-container'>
        <img src={ logo } alt="site-logo" className='site-logo' />
        { logoUrl && <img className='header-image' src={ logoUrl } alt={ title } /> }
        { title }
      </div>
      { showHome && <button onClick={ () => { navigate('/'); }} className='home-button'> Home </button> }
    </header>
  )
}
