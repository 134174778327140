import { useNavigate } from 'react-router-dom';

export default function ChannelCard({channel: { id, name, image, online}}) {
  const navigate = useNavigate();

  return (
    <div>
    <button onClick={ () => { navigate(`/channel/${id}`); }} className='channel-card'>
      <img className='card-image' src={ image } alt={ name } />
        { online ? '🟢' : '🔴' } { ' ' } { name }
    </button>
    </div>
  )
}
