import Channels from '../components/Channels';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Home () {
  document.title = `DiaZ IPTV - Home`;
  return(
    <div>
      <Header title='IPTV do DiaZ' />
      <Channels />
      <Footer />
    </div>
  )
}
