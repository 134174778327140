import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import channels from '../sources/channels.json';
import Header from './Header';
import Footer from './Footer';

export default function Player() {
  const [channelData, setChannelData] = useState();
  const [channelStatus, setChannelStatus] = useState('');
  const [streamUrl, setStreamUrl] = useState('');
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const { id } = useParams();

  const getUrl = async () => {
    const channel = await channels.find((channel) => channel.id === id);
    if(channel) {
      const isOnline = channel.online ? '🟢' : '🔴 [OFFLINE] ';
      setChannelStatus(isOnline);
      // setChannelData(channel);
      document.title = `DiaZ IPTV - ${channel.name}`;
    };
    setChannelData(channel);
  }
  
  useEffect(() => {
    getUrl();
  });
  
  return(
    <div>
      { channelData && <Header title={ `${channelStatus} ${channelData.name}` } showHome='true' logoUrl={ channelData.image } /> }
      <div className='player-container'>
        { (!streamUrl && channelData) && <h3 className='h3-desc'>Para assistir este canal, selecione um Stream nos Botões Abaixo</h3> }
        { channelData && <ReactPlayer
          controls={true}
          onReady
          pip={true}
          playing={true}
          url={streamUrl}
          width={ windowSize.current[0] <= 720 ? '320px' : '640px' }
          height={ windowSize.current[1] <= 580 ? '240px' : '480px' }
        /> }
        { channelData && <p className='p-description'>{channelData.description}</p> }
        { !channelData && <h1> ERRO! CANAL NÃO ENCONTRADO OU FORA DO AR.</h1>}
        <p className='streams-p'>Streams:</p>
        <div className='streams-container'>
          { channelData && channelData.urls.map((url) => (
            <button className='stream-button' onClick={() => { setStreamUrl(url.streamUrl)}} >
              {url.name}
            </button>
          )) }
        </div>
      </div> 
      <Footer />
    </div>
  )
}
