import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Player from './components/Player';
import './App.css';

export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={ <Home/> } />
          <Route path="/channel/" element={ <Player/> } />
          <Route path="/channel/:id" element={ <Player/> } />
        </Routes>
      </Router>
    </div>
  );
}
