import { useEffect } from 'react';
import { useState } from 'react';
import channels from '../sources/channels.json';
import ChannelCard from './ChannelCard';

export default function Channels() {
  const [allChannels, setAllChannels] = useState([]);
  const [filteredChannels, setFilteredChannels] = useState([]);

  const sortChannels = async () => {
    const sortedChannels = await channels.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

    const filteredChannels = [];

    sortedChannels
      .forEach((channel) => {
        if (channel.id.toLowerCase() !== 'aaaaa') {
          filteredChannels.push(channel);
        }
    });

    setAllChannels([...filteredChannels]);
  }

  const handleChange = ({ target: { value } }) => {
    const inputValue = value.toLowerCase();

    const newFiltered = allChannels
      .filter((channel) => 
        channel.name.toLowerCase().includes(inputValue)
        || channel.description.toLowerCase().includes(inputValue)
        || channel.id.toLowerCase().includes(inputValue)
        || channel.categories.includes(inputValue)
        );

    setFilteredChannels(newFiltered);
  };

  useEffect(() => {
    setFilteredChannels(allChannels);
  }, [allChannels]);

  useEffect(() => {
    sortChannels();
  }, []);

  return(
    <div>
      <div className='inputcontainer'>
        <p>Pesquisar:</p>
        <input type='text' name='search' id='search' className='inputsearch' onChange={ (e) => { handleChange(e) }}/>
      </div>

      <div className='channels-container'>
        { filteredChannels.map((channel) => (
          <ChannelCard name={channel.name} key={ channel.id } channel={ channel } />
        ))}
      </div>
    </div>
  )
}
